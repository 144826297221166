import moment from 'moment'
import confirm from '../confirm/confirm.vue'
import { EventBus } from '../events/eventBus'
import Vue from 'vue'

export default {
  name: 'IssuesList',

  data: () => ({
    loading: true,
    addIssueButtonVisible: false,
    editDod: false,
    dateMenu: false
  }),

  created () {
    const scope = this
    setTimeout(function () {
      scope.addIssueButtonVisible = true
    }, 1000)
  },

  components: {
    confirm: confirm
  },

  computed: {
    formattedDate () {
      if (this.area.dateOfDelivery === null || this.area.dateOfDelivery === undefined) {
        return this.selectedDate
      } else {
        const newDate = this.formatDate(this.area.dateOfDelivery)
        return newDate
      }
    },
    search () {
      return this.$store.getters['session/search']
    },
    getPlot () {
      return this.$store.getters['areas/selectedArea']
    },
    issues () {
      if (this.$route.params.id) {
        let areaIssues = this.$store.getters['areas/areaIssues']
        if (this.search) {
          areaIssues = areaIssues.filter(issue => {
            if (issue.description) {
              return issue.description.toLowerCase().includes(this.search.toLowerCase())
            } else {
              return false
            }
          })
        }

        return areaIssues
      } else {
        let issues = this.$store.getters['issues/issues']

        if (this.search) {
          issues = issues.filter(issue => {
            if (issue.description) {
              return issue.description.toLowerCase().includes(this.search.toLowerCase()
              )
            } else {
              return false
            }
          })
        }

        return issues
      }
    }
  },

  async mounted () {
    const scope = this

    EventBus.$on('refreshData', async function (data) {
      await scope.getData()
    })

    await scope.getData()
  },

  beforeDestroy () {
    this.addIssueButtonVisible = false
    EventBus.$off('refreshData')
  },

  methods: {
    formatDate (date) {
      if (date !== null && date !== undefined) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    async getData () {
      if (this.$route.params.id) {
        await this.getAreaIssues()
        await this.$store.dispatch('areas/retrieveArea', this.$route.params.id)
      } else {
        await this.getIssues()
      }
    },
    showIssue (issue) {
      // Parents shown in separate list.
      if (issue.isParent) {
        return false
      }
      if (!this.$store.getters['session/getCognitoGroup']('user')) {
        return true
      } else {
        // Don't show any issue which has a parent, as the parent list is shown above.
        if (issue.parentId) {
          return false
        }
      }
      return true
    },
    async getAreaIssues () {
      if (this.issues == null || this.issues.length === 0) {
        this.loading = true
      }
      try {
        await this.$store.dispatch('areas/retrieveAreaIssues', this.$route.params.id)
      } catch (err) {
        this.$snotify.error(err.message)
      } finally {
        this.loading = false
      }
    },
    /**
     * Call server to delete issue.
     * @param {*} issue Issue to delete.
     */
    deleteIssue (issue) {
      const scope = this
      this.$refs.confirm.open(this.$t('message.deleteIssue'), this.$t('message.deleteIssue'), { color: 'primary' }).then(async (confirm) => {
        if (confirm) {
          try {
            await scope.$store.dispatch('issues/deleteIssue', issue.id)
            scope.loading = true
          } catch (err) {
            scope.$snotify.error(err.message)
          } finally {
            scope.loading = false
          }
        }
      })
    },
    async getIssues () {
      this.loading = true
      try {
        await this.$store.dispatch('issues/retrieveIssues')
      } catch (err) {
        this.$snotify.error(err.message)
      } finally {
        this.loading = false
      }
    },
    getIssueStatus (issue) {
      // Removing any underscore or dash
      return issue.status.replace(/_|-/g, ' ')
    },
    getTotalIssuesCount (issue) {
      let count = 0
      if (issue.issues.length !== 0) {
        count += issue.issues.length
      }
      return count
    },
    updatedAt (issue) {
      return moment.utc(issue.updatedAt).local().format('DD-MM-YYYY @ HH:mm')
    },
    createdDateCompare (issue) {
      const currentDate = moment()
      const dateDifference = currentDate.add(2, 'days')
      if (currentDate < dateDifference && currentDate > issue.createdAt) {
        return true
      } else {
        return false
      }
    },
    listStatusIssuesCount (status, issue) {
      if (issue.issues) {
        const issuesOpen = issue.issues.filter(x => x.status === status).length
        return issuesOpen
      }
      return 0
    },
    getCreateLink () {
      if (this.$route.params.id) {
        return `/createIssue?areaId=${this.$route.params.id}`
      } else {
        return '/createIssue'
      }
    },
    async submitIssue (issue) {
      this.$refs.confirm.open('Submit Issue', `Are you sure you want to submit ${issue.description}?`, { color: 'primary' }).then(async (confirm) => {
        if (confirm) {
          const copiedIssue = {
            id: issue.id,
            title: issue.description,
            status: 'submitted'
          }
          const scope = this
          try {
            await this.$store.dispatch('issues/updateIssue', copiedIssue)
            await scope.$store.dispatch('issues/retrieveIssues')
          } catch (err) {
            scope.$snotify.error(err.message, {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true
            })
          } finally {
            scope.loading = false
          }
        }
      })
    },
    async generateReport (plot) {
      this.$refs.confirm.open('Generate Report', 'Generate PDF Report for Plot', { color: 'primary' }).then(async (confirm) => {
        if (confirm) {
          Vue.set(plot, 'generating', true)
          const queryData = {
            id: plot.id
          }
          const scope = this
          try {
            await scope.$store.dispatch('areas/generateReport', queryData)
            scope.$snotify.success('Report has been generated and emailed to you.')
          } catch (err) {
            scope.$snotify.error(err.message, {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true
            })
          } finally {
            Vue.set(plot, 'generating', false)
          }
        }
      })
    }
  }
}
